import styled from "styled-components"

export const LargeText = styled.div`
  p {
    font-size: 1.6rem;
    padding: 5px 40px;
    text-align: center;
    padding-top: 30%;
  }
`
